<template>
  <section
    class="city-selection__root"
  >
    <div class="city-selection__deck">
      <SelectCityDesktop
        :cities-list="cityList()"
      />
    </div>
    <div class="city-selection__mob">
      <SelectCityMobile
        :cities-list="cityList()"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import { useUserCity } from '#imports';

export type CitySelectionStubBlockType = {
	title?: string;
	city?: string;
	id?: string;
	isActive?: boolean;
	anchor?: string;
	relocation?: string;
	href?: string;
	modalTitle?: string;
	linkDev?: string;
	linkProd?: string;
	address?: string;
	time?: string[];
	phone?: {
		phone: string;
		desc?: string;
	}[];
	email?: {
		email: string;
		desc?: string;
	}[];
}

type CitySelectionStubBlockBlock = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
	values: any;
	cfgs: SectionTypes;
}

const props = defineProps<CitySelectionStubBlockBlock>();
const { checkCurrentCity } = useUserCity();
const { baseDomain } = useRuntimeConfig().public

const cityList = (): CitySelectionStubBlockType[] => [
  {
    city: 'ТЮМЕНЬ',
    id: 'tmn',
    isActive: false,
    anchor: 'open_t',
    relocation: `//tmn.${baseDomain}`,
    modalTitle: 'Отдел продаж',
    address: 'г. Тюмень, ул. Сакко 24, офис 215 Клубный дом “Вивальди”',
    time: ['Пн - Пт: &nbsp;&nbsp; 09:00 – 19:00', 'Сб: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 10:00 – 15:00',
      'Вс: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; выходной'],
    phone: [{ phone: '+7 (3452) 28-81-60' }],
    email: [{ email: 'kvartiratmn@astongroup.ru' }]
  },
  {
    city: 'МОСКВА',
    id: 'msk',
    isActive: false,
    anchor: 'open_m',
    relocation: `//msk.${baseDomain}`,
    modalTitle: 'Скоро в Вашем городе'
  },
  {
    city: 'ЕКАТЕРИНБУРГ',
    id: 'ekb',
    isActive: false,
    anchor: 'open_e',
    relocation: `//ekb.${baseDomain}`,
    href: 'https://aston.click/',
    modalTitle: 'Отдел продаж',
    address: 'г. Екатеринбург, пр. Ленина 25, ТЦ&nbsp;Европа (вход с ул. Урицкого)',
    time: ['Пн - Пт: &nbsp 09:00 – 19:00', 'Сб: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 10:00 – 14:00', 'Вс: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; выходной'],
    phone: [{ phone: '+7 (343) 363-88-09' }],
    email: [{ email: 'ekaterinburg@astongroup.ru' }]
  },
  {
    title: 'стройтрест',
    city: 'Новосибирск',
    id: 'nsk',
    isActive: false,
    anchor: 'open_n',
    relocation: `//nsk.${baseDomain}`,
    modalTitle: 'Отдел продаж',
    address: 'г. Новосибирск, ул.&nbsp;Серебренниковская 19/1',
    time: ['Пн - Пт: &nbsp 09:00 – 18:00', 'Сб - Вс: &nbsp;&nbsp; выходной'],
    phone: [{ phone: '+7 (383) 312-11-12' }, { phone: '+7 (383) 354-81-60', desc: '(приемная)' }],
    email: [{ email: 'salesnovosibirsk@astongroup.ru' }, {
      email: 'trest43@astongroup.ru',
      desc: '(приемная)'
    }]
  }
];
onMounted(async () => {
  await checkCurrentCity();
});

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.city-selection__root {
	position: fixed;
	inset: 0;
}

.city-selection__deck {
	display: block;
}

.city-selection__mob {
	display: none;
}

@include media('lg') {
	.city-selection__deck {
		display: none;
	}

	.city-selection__mob {
		display: block;
	}
}
</style>
